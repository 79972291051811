<template>
  <header :class="{ header_login: !loginStatus }">
    <ul class="clearfix" :class="{ maxWidth: loginStatus }">
      <li class="fl">
        <!-- 登入後 顯示 -->
        <img
          src="@/assets/images/header/scroll.png"
          class="header_btn"
          id="menu_open_button"
          @click="openMenu"
          v-show="loginStatus"
          data-testid="header_btn"
        />
        <!-- 通知 -->
        <HeaderNotification></HeaderNotification>

        <!-- login page 顯示 -->
        <div v-show="!loginStatus">
          <router-link to="/home" class="logo" data-testid="goToHome">
            <img class="logoImg" />
          </router-link>
        </div>
      </li>
      <!-- 登入顯示 -->
      <li class="fr" v-show="loginStatus">
        <el-dropdown trigger="click" @command="handleCommand" @visible-change="addStyle" data-testid="dropdown">
          <img src="@/assets/images/header/profile.png" alt class="member_logo" />
          <!-- logout_icon -->
          <el-dropdown-menu slot="dropdown" class="login_dropdown_box">
            <el-dropdown-item command="goToIbPortal" class="login_back" v-show="isIb" data-testid="goToIbPortal">
              <div class="icon"></div>
              <span>{{ $t('common.button.goToIB') }}</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout" class="logout" data-testid="logout">
              <div class="icon"></div>
              <span>{{ $t('common.button.logout') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>

      <li class="flag" :class="{ flagLogin: loginStatus }" v-show="showLang">
        <el-dropdown id="lang" trigger="click" :hide-on-click="false" @command="langCommand" data-testid="dropdownFlag">
          <img class="areaImg" v-if="!loginStatus" src="@/assets/images/header/language-white.png" alt />
          <img class="areaImg" v-else src="@/assets/images/header/language.png" alt />
          <span class="name" v-html="language.label"></span>
          <span v-if="!loginStatus" class="login_lang_down lang_down"></span>
          <el-dropdown-menu slot="dropdown" class="lang_dropdown_box" placement="right-end">
            <el-dropdown-item
              v-for="item in languages"
              :key="item.value"
              :command="item"
              :class="{ active: lang == item.value }"
              :data-testid="item.value"
            >
              <img :src="item.icon" />
              <span>{{ item.label }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <li class="fr sign-up">
        <el-button class="sign" @click="toSignUp">
          Sign Up
        </el-button>
      </li>
    </ul>
  </header>
</template>

<script>
import helper from '@/util/signinHelper';
import HeaderNotification from '@/components/notification/HeaderNotification';

export default {
  name: 'vHeader',
  props: {
    matches: Boolean
  },
  components: {
    HeaderNotification
  },
  data() {
    return {
      isIb: false,
      showLang: true,
      language: '',
      languages: [
        {
          value: 'en_US',
          label: 'English',
          icon: require('@/assets/images/flag/uk_flag.png')
        },
        {
          value: 'fr_FR',
          label: 'Français',
          icon: require('@/assets/images/flag/fr_flag.png')
        },
        {
          value: 'es',
          label: 'Español',
          icon: require('@/assets/images/flag/es_flag.png')
        },
        {
          value: 'pt',
          label: 'Português',
          icon: require('@/assets/images/flag/pt_flag.png')
        },
        {
          value: 'de',
          label: 'Deutsch',
          icon: require('@/assets/images/flag/de_flag.png')
        },
        {
          value: 'it',
          label: 'Italian',
          icon: require('@/assets/images/flag/it_flag.png')
        },
        {
          value: 'pl',
          label: 'Poland',
          icon: require('@/assets/images/flag/pl_flag.png')
        }
      ],
      specificUrlLanguageSetting: [
        {
          url: 'myaccount.vtmarketsindo.com',
          lang: 'id'
        }
      ],
      openImg: false,
      hostName: location.hostname
    };
  },
  watch: {
    loginStatus(loginStatus) {
      if (loginStatus) {
        this.getUserInfo();
      } else {
        this.autoChangeLangByHostName();
      }
    },
    language(lang) {
      this.lang = lang.value;
      document.body.dir = lang.value === 'ar' ? 'rtl' : 'ltr';
    },
    lang: {
      immediate: true,
      handler(val) {
        // 如果找不到預設第一筆
        this.language = this.languages.find(f => f.value == val) || this.languages[0];
        this.setChatLanguage(val);
      }
    },
    $route: {
      handler(route) {
        this.showLang = route.meta.restrictLang ? false : true;
      },
      immediate: true
    }
  },
  computed: {
    userName() {
      return this.$store.state.common.userName;
    },
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    userType() {
      return this.$store.state.common.userType;
    },
    lang: {
      get() {
        return this.$store.state.common.lang;
      },
      set(value) {
        return this.$store.commit('common/setLang', value);
      }
    },
    isWantAutoSetLangByHostName: {
      get() {
        return sessionStorage.getItem('isWantAutoSetLangByHostName');
      },
      set(value) {
        return sessionStorage.setItem('isWantAutoSetLangByHostName', value);
      }
    }
  },
  methods: {
    toSignUp() {
      window.location = 'https://www.alphatick.com/sign-up/  ';
    },
    addStyle(bool) {
      this.openImg = bool;
    },
    openMenu() {
      this.$emit('openMenu');
    },
    logout() {
      helper.signOut(null,'manually');
      this.isWantAutoLangByHostName = true;
    },
    handleCommand(command) {
      if (command == 'logout') this.logout();
      else if (command == 'goToIbPortal' && this.isIb) this.$redirect.redirectToIbPortal();
    },
    langCommand(command) {
      this.language = command;
      //see if language is changed in the landing page
      if (!this.loginStatus) {
        this.$store.commit('common/setIsChangedLangAtLandingPage', true);
      }
    },
    getUserInfo() {
      this.isIb = this.userType === 5 || this.userType === 16 ? true : false;
    },
    autoChangeLangByHostName() {
      for (let { lang, url } of this.specificUrlLanguageSetting) {
        if (this.hostName.includes(url)) {
          const language = this.languages.find(langObj => langObj.value === lang);
          this.langCommand(language);
        }
      }
    },
    setChatLanguage(val) {
      zE(function() {
        $zopim(function() {
          setTimeout(() => {
            $("div[data-test-id='ChatWidgetButton']").remove();
          }, 0);
          $zopim.livechat.setLanguage(val);
        });
      });
    }
  },
  mounted() {
    //for already logged-in users
    if (this.loginStatus) {
      this.getUserInfo();
    } else {
      if (this.isWantAutoSetLangByHostName === 'true' || this.isWantAutoSetLangByHostName === null) {
        this.autoChangeLangByHostName();
        this.isWantAutoSetLangByHostName = false;
      }
    }

    onbeforeunload = () => {
      this.isWantAutoSetLangByHostName = true;
    };

    let callback = (val, oldVal, uri) => {
      console.log('localStorage change', val);
      if (val != this.userName) location.reload();
    };

    this.$ls.on('setUserName', callback); //watch change foo key and triggered callback
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/header.scss';
</style>
