<template>
  <vDialog title="notification" :visible.sync="visible" :visibleFooter="true" :needToBody="true">
    <div class="dialog_body">
      <p class="title" v-html="enabledNoticeTitle"></p>
      <div v-html="enabledNotice"></div>
    </div>
  </vDialog>
</template>

<script>
import vDialog from '@/components/home/vDialog';

export default {
  name: 'notice',
  components: { vDialog },
  props: {
    notificationVisible: Boolean,
    enabledNotice: String,
    enabledNoticeTitle: String
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    notificationVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:notificationVisible', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/notification/notice.scss';
</style>
